import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import * as PageChatCtx from './chat-ctx';
import { ChatMsgArea } from './chat-msg-area/chat-msg-area';
import { ChatUserList } from './chat-user-list/chat-user-list';
import { SearchInput } from '../../common/search-input';
import { LoadingDiscoWithContainer } from '../../common/loading/loading-disco';
import { useBusiness, useUser } from '../../../graphql/graph-hooks';
import { GET_CHAT_PAGE_LISTS_PAGINATED } from '../../../graphql/queries/conversation-page-lists';
import { logError } from '../../../helpers/errors/bug-report';
import { useDebounce } from 'helpers/hooks/use-debounce';
import { useModal, useFetchMore as useFetchMoreConversations } from 'components/global-hooks';
import { UI_MODALS } from 'helpers/enums';
import { useGlobalCtx } from 'components/global-context';
import { APIConstants } from 'helpers/constants';

import S from './chat-page.module.scss';
import { CONVERSATION_MARK_AS_READ } from 'graphql/mutations/conversation-mark-as-read';

const ChatPage = ({ isAdminChat, chatBizId }) => {
  const { initModal } = useModal();
  const { state } = useGlobalCtx();
  const { t, i18n } = useTranslation();

  const {
    dispatch,
    state: { filter, conversationId },
  } = PageChatCtx.usePageChatCtx();
  const { id: userId, admin: isAdmin } = useUser();
  const { id: businessId } = useBusiness();

  const handleFilterChange = (text) => dispatch(PageChatCtx.setChatFields({ filter: text, conversationId: null }));
  const { value: filterValue, onChange: onFilterChange } = useDebounce(filter, handleFilterChange);

  const { loading, error, data, fetchMore, refetch } = useQuery(GET_CHAT_PAGE_LISTS_PAGINATED, {
    variables: {
      chatBizId,
      blockedByBusinessId: businessId,
      inGroupBusinessId: businessId,
      userId,
      isAdmin,
      first: 20,
      senderSearch: filter,
      sortByUnreadMessages: true,
      sortByLastMessageSent: true,
    },
    fetchPolicy: 'network-only',
    onError: (err) => logError(err, 'GET_CHAT_PAGE_LISTS_PAGINATED', ChatPage.displayName),
  });

  const {
    isLoading: isFetchMoreLoading,
    fetchMore: fetchMoreConversations,
    error: fetchMoreError,
  } = useFetchMoreConversations('conversations_paginated');

  const [markAsRead] = useMutation(CONVERSATION_MARK_AS_READ, {
    variables: {
      id: conversationId,
      isAdmin,
      blockedByBusinessId: businessId,
      inGroupBusinessId: businessId,
    },
    onError: (error) => console.log('GB|err', error),
  });

  const handleAvatarImageClick = (dancerId) => {
    initModal(UI_MODALS.DANCER, {
      dancerId,
    });
  };

  const handleOnScrollBottom = () => {
    if (loading || isFetchMoreLoading || !data.conversations_paginated.pageInfo.hasNextPage) {
      return;
    }
    fetchMoreConversations(fetchMore, {
      after: data.conversations_paginated.pageInfo.endCursor,
    });
  };

  useEffect(() => {
    refetch();
  }, [filter, refetch, state.app.unreadBusiness, state.app.unreadAdmin]);

  useEffect(() => {
    const cid = new URLSearchParams(document.location.search).get('cid');
    dispatch(PageChatCtx.setChatFields({ conversationId: cid }));
  }, []);

  useEffect(() => {
    const cid = new URLSearchParams(document.location.search).get('cid');
    dispatch(PageChatCtx.setChatFields({ conversationId: cid }));
  }, []);

  useEffect(() => {
    if (conversationId && data) {
      const conversation = data.conversations_paginated.nodes.find(({ id }) => id === conversationId);
      if (conversation?.business_unread_messages_count && (!isAdmin || (isAdmin && isAdminChat))) {
        markAsRead();
      }
    }
  }, [conversationId, data]);

  if (error) {
    return <h1 className={S.errorPage}>{t('chatPage.error_loading_conversations')}</h1>;
  }

  return (
    <>
      <div className={S.pageWrapper}>
        <h1 className={S.h1Heading}>
          {isAdminChat ? 'Admin ' : ''}
          {i18n.language === 'en' ? t('chatPage.conversations') : t('chatPage.conversations').toLowerCase()}
        </h1>
        <div className={S.chatArea}>
          <div className={S.chatListArea}>
            <div className={S.space} />
            <SearchInput
              className={S.searchInput}
              placeholder={t('chatPage.search_conversations')}
              onChange={onFilterChange}
            />
            {loading ? (
              <LoadingDiscoWithContainer />
            ) : (
              <ChatUserList
                isLoading={loading || isFetchMoreLoading}
                conversations={data.conversations_paginated.nodes}
                onScrollBottom={handleOnScrollBottom}
                avatarOnClick={handleAvatarImageClick}
                error={error || fetchMoreError}
                isAdminChat={isAdminChat}
                isAdmin={isAdmin}
              />
            )}
          </div>
          <ChatMsgArea isAdminChat={isAdminChat} avatarOnClick={handleAvatarImageClick} />
        </div>
      </div>
    </>
  );
};

ChatPage.displayName = 'ChatPage';
ChatPage.propTypes = {
  isAdminChat: PropTypes.bool.isRequired,
  chatBizId: PropTypes.string.isRequired,
};

export { ChatPage };
