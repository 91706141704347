import React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { DancerSearchGrid } from './dancer-search-grid';
import { DancerSortAndSelectedRow } from './dancer-sort-and-selected-row';
import S from './dancer-search.module.scss';
import { Filters } from './search-filter/dancer-page-filters';
import { AdminFilters } from './search-filter/dancer-page-admin-filters';
import { getDancersSearchHeading } from '../services/get-dancer-search-heading';
import { DancerSearchProvider } from '../dancer-search-context';
import { DANCER_PAGES } from '../../../../../helpers/enums';
import posthog from 'posthog-js';

const DancerSearch = ({ page }) => {
  const { t } = useTranslation();
  const HEADING = t(getDancersSearchHeading(page));
  const IS_SEARCH = [DANCER_PAGES.SEARCH, DANCER_PAGES.ADMIN_SEARCH].includes(page);
  const IS_ADMIN_SEARCH = [DANCER_PAGES.ADMIN_SEARCH, DANCER_PAGES.SUSPENDED].includes(page);
  const IS_SUSPENDED = page === DANCER_PAGES.SUSPENDED;

  posthog.capture('DancerSearchPage');

  return (
    <DancerSearchProvider page={page}>
      <div className={S.pageWrapper}>
        <h1 className={S.dancerSearchHeading}>{HEADING}</h1>

        {IS_SEARCH &&
          (IS_ADMIN_SEARCH ? (
            <AdminFilters />
          ) : (
            <>
              <Filters />
              <DancerSortAndSelectedRow />
            </>
          ))}

        <DancerSearchGrid IS_SEARCH={IS_SEARCH} IS_ADMIN_SEARCH={IS_ADMIN_SEARCH} IS_SUSPENDED={IS_SUSPENDED} />
      </div>
    </DancerSearchProvider>
  );
};

DancerSearch.displayName = 'DancerSearch';

DancerSearch.propTypes = {
  page: PropTypes.oneOf(Object.values(DANCER_PAGES)),
};

export { DancerSearch };
