import classNames from 'classnames';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FaExclamationTriangle } from 'react-icons/fa';

import S from './photo-verification-item.module.scss';
import { useCallback, useState } from 'react';
import { PHOTO_VERIFICATION_REJECTION_REASONS, UI_MODALS } from '../../../../helpers/enums';
import { PHOTO_VERIFICATION_REJECTION_REASON_TRANSLATION } from 'helpers/constants';
import { FormSelectField } from '../../../common/form/form-select-field';
import ImgMissing from 'components/common/images/img-missing';
import moment from 'moment';
import { useModal } from 'components/global-hooks';

const PhotoVerificationItem = ({ picture, submitVerification, updating, className }) => {
  const { t, i18n } = useTranslation();
  const [rejectionReason, setRejectionReason] = useState(picture.rejection_text);
  const handleRejectionReasonChange = useCallback((e) => setRejectionReason(e.target.value), [setRejectionReason]);

  if (picture.user === null) {
    return null;
  }

  const { initModal } = useModal();
  const handleViewProfile = useCallback(
    () =>
      initModal(UI_MODALS.DANCER, {
        dancerId:picture.user.id
      }),
    [initModal,picture.user.id]
  );
  return (
    <div className={classNames('flex mb-5 p-4', className)}>
      <div style={{ flex: '0 1', flexDirection: 'column' }}>
        <div className={S.verificationPhotoInfo}>
          <div className={S.dataRow}>
            <label>{t('common.name')}:</label>
            <span onClick={handleViewProfile} className={S.name}>{picture.user.name}</span>
          </div>

          <div className={S.dataRow}>
            <label>{t('common.email')}:</label>
            <span className={S.sentence}>{picture.user.email}</span>
          </div>

          <div className={S.dataRow}>
            <label>{t('common.photo_submitted')}:</label>
            <span className={S.sentence}>{`${moment(picture.data_updated_at).tz('America/Chicago').format('MM/DD/YYYY, h:mm A')} CST`}</span>
          </div>
        </div>
      </div>

      <div style={{ flex: '0 1', flexDirection: 'column' }}>
        <div className={S.verificationPhotoInfo}>
          {picture.moderated_at !== null &&
            <div className={S.dataRow}>
              <label>{t('common.moderated_at')}:</label>
              <span className={S.sentence}>{`${moment(picture.moderated_at).tz('America/Chicago').format('MM/DD/YYYY, h:mm A')} CST`}</span>
            </div>
          }
          {picture.moderated_by !== null &&
            <div className={S.dataRow}>
              <label>{t('common.moderated_by')}:</label>
              <span className={S.sentence}>{picture.moderated_by.name}</span>
            </div>
          }
        </div>
      </div>

      <div className={`flex ${S.imageWrapper}`}>
        {picture.medium ? (
          <div className='mb-2'>
            <img
              alt={picture.user.name}
              src={picture.medium}
              className='mr-8 h-24 w-24 hover:translate-y-24 hover:scale-300 hover:transition-transform hover:z-50'
            />
          </div>
        ) : (
          <div className='mb-1'>
            <ImgMissing text={t('common.missing').toUpperCase()} />
          </div>
        )}
        <div>
          {t('photoVerificationPage.profile_picture')} #{picture.position + 1}
        </div>
      </div>

      <div className={`flex mr-8 ${S.imageWrapper}`}>
        <div className='mb-2'>
          {picture.user.verification_check?.first_image ? (
            <img
              alt={picture.user.name}
              src={picture.user.verification_check?.first_image?.medium}
              className='h-24 w-24 hover:translate-y-24 hover:scale-300 hover:transition-transform hover:z-50'
            />
          ) : (
            <ImgMissing text={t('common.missing').toUpperCase()} />
          )}
        </div>
        <div>{t('photoVerificationPage.verification_photo')}</div>
      </div>
      <div className='flex flex-col justify-around pl-12'>
        {!picture.is_verified && (
          <button
            disabled={updating}
            onClick={() => submitVerification({ id: picture.id, isVerified: true })}
            className={`inline-flex items-center rounded border border-transparent bg-green-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 ${updating && 'disabled:opacity-50'
              }`}
          >
            {t('common.approve')}
          </button>
        )}
        {(picture.is_verified || picture.is_verified === null) && (
          <button
            disabled={updating || !rejectionReason}
            onClick={() => submitVerification({ id: picture.id, isVerified: false, rejectionReason })}
            className={`inline-flex items-center rounded border border-transparent bg-red-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 ${(!rejectionReason || updating) && 'disabled:opacity-50'
              }`}
          >
            {t('common.reject')}
          </button>
        )}
      </div>
      <div className='flex flex-col justify-around pl-12'>
        <FormSelectField
          label={t('photoVerificationPage.rejection_text')}
          id='photo_verification-rejection_text'
          name='rejectionReason'
          value={rejectionReason}
          handleChange={handleRejectionReasonChange}
          isDisabled={updating || picture.is_verified === false}
          options={[
            { value: '', label: t('common.select_option') },
            {
              value: PHOTO_VERIFICATION_REJECTION_REASONS.LOW_QUALITY,
              label: PHOTO_VERIFICATION_REJECTION_REASON_TRANSLATION.LOW_QUALITY[i18n.language],
            },
            {
              value: PHOTO_VERIFICATION_REJECTION_REASONS.OFFENSIVE,
              label: PHOTO_VERIFICATION_REJECTION_REASON_TRANSLATION.OFFENSIVE[i18n.language],
            },
            {
              value: PHOTO_VERIFICATION_REJECTION_REASONS.MULTIPLE_PEOPLE,
              label: PHOTO_VERIFICATION_REJECTION_REASON_TRANSLATION.MULTIPLE_PEOPLE[i18n.language],
            },
            {
              value: PHOTO_VERIFICATION_REJECTION_REASONS.PROFESSIONAL_GUIDELINES,
              label: PHOTO_VERIFICATION_REJECTION_REASON_TRANSLATION.PROFESSIONAL_GUIDELINES[i18n.language],
            }
          ]}
        />
      </div>
    </div>
  );
};

PhotoVerificationItem.displayName = 'PhotoVerificationItemPresenter';
PhotoVerificationItem.propTypes = {
  picture: PropTypes.object.isRequired,
  className: PropTypes.string,
  submitVerification: PropTypes.func.isRequired,
  updating: PropTypes.bool.isRequired,
};

export { PhotoVerificationItem };
