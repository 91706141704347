import React, { useRef, useCallback, useMemo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePageChatCtx } from 'components/pages-login/chat/chat-ctx';

import S from './chat-msg-body.module.scss';
import { MsgItem } from './msg-item';
import classNames from 'classnames';

type ChatMsgProps = {
  isLoading: boolean;
  isFetchMoreLoading: boolean;
  messages: MsgItemType[];
  targetId: string;
  conversationId: string;
  onScrollTop: any;
  error: string | undefined;
};

const ChatMsgBody: React.FC<ChatMsgProps> = ({
  isLoading,
  messages,
  targetId,
  conversationId,
  onScrollTop,
  isFetchMoreLoading,
  error,
}): React.ReactElement => {
  const MSG_CONTAINER = useRef<HTMLElement>();
  const SET_REF = useCallback((el) => (MSG_CONTAINER.current = el), [MSG_CONTAINER]);
  const {
    state: { firstUnreadId, scrollToBottom },
  } = usePageChatCtx();

  const [lastScrollHeight, setLastScrollHeight] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    setLastScrollHeight(0);
  }, [conversationId]);

  useEffect(() => {
    if (MSG_CONTAINER.current) {
      const top = scrollToBottom
        ? MSG_CONTAINER.current.scrollHeight
        : MSG_CONTAINER.current.scrollHeight - lastScrollHeight;
      MSG_CONTAINER.current.scrollTo({ top, behavior: scrollToBottom ? 'smooth' : 'auto' });
      if (messages.length) {
        setLastScrollHeight(MSG_CONTAINER.current.scrollHeight);
      }
    }
  }, [MSG_CONTAINER.current, messages, scrollToBottom]);

  const MESSAGES = useMemo(
    () =>
      messages.map((msg) => {
        return {
          ...msg,
          isFirstUnRead: firstUnreadId === msg.id,
          // horrible, horrible fix to avoid the insane merging of consecutive messages
          firstMsg: true,
          showTime: true,
        };
      }),
    [messages, firstUnreadId]
  );

  if (!error && !isLoading && MESSAGES.length === 0) {
    return <h3 className={S.warnMsg}>{t('chatPage.no_messages')}</h3>;
  }

  const handleOnScroll = (e: any) => {
    if (!isLoading && (e.target as HTMLElement).scrollTop === 0) {
      onScrollTop();
    }
  };

  return (
    <div className={S.messageList} onScroll={handleOnScroll} ref={SET_REF}>
      {
        <h3
          className={classNames(S.warnMsg, {
            [S.hidden]: !error && !isLoading && !isFetchMoreLoading,
            [S.errorMsg]: error,
          })}
        >
          {error || t('common.loading')}
        </h3>
      }
      {!isLoading &&
        MESSAGES.map((message) => {
          return (
            <MsgItem
              key={`MESSAGE_${message.id}`}
              {...message}
              targetId={targetId}
              showNewMsgIndicator={!!firstUnreadId}
            />
          );
        })}
      <div className={S.clearFix} />
    </div>
  );
};

ChatMsgBody.displayName = 'ChatMsgBody';

ChatMsgBody.defaultProps = {
  messages: [],
};

export { ChatMsgBody };
