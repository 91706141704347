import { gql } from 'apollo-boost';
import { BLAST_KEY_FRAGMENT } from '../fragments/blast-fragment';

export const CREATE_BLAST_GQL = gql`
  ${BLAST_KEY_FRAGMENT}
  mutation BlastAnnouncementCreate(
    $type: String!
    $title: LocalizedStringHash!
    $message: LocalizedStringHash!
    $targetUrl: String
  ) {
    blast_announcement_create(input: { blastType: $type, title: $title, message: $message, targetUrl: $targetUrl }) {
      blast_announcement {
        ...BlastKeyFragment
      }
    }
  }
`;
