import { gql } from 'apollo-boost';

export const BLAST_KEY_FRAGMENT = gql`
  fragment BlastKeyFragment on BlastAnnouncement {
    id
    blast_type
    title
    message
    target_url
    created_at
    user {
      id
      name
      first_image {
        id
        thumb
      }
    }
  }
`;
