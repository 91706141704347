import { useCallback, useEffect, useRef, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useBusiness } from 'graphql/graph-hooks';
import { useTranslation } from 'react-i18next';
import throttle from 'lodash/throttle';

import S from './dancer-search-grid.module.scss';
import * as DancerSearchCTX from '../dancer-search-context';
import { removeDancerFromList } from '../services/remove-dancer-from-list';
import { DancerGridItem } from '../../../../common/dancer-grid-item/dancer-grid-item';
import { LoadingDiscoWithContainer } from '../../../../common/loading/loading-disco';
import { logError } from '../../../../../helpers/errors/bug-report';
import {
  GET_DANCER_ADMIN_SEARCH_SQL,
  GET_DANCER_MANAGER_SEARCH_SQL,
} from '../../../../../graphql/queries/dancer-search-query';

const DancerSearchGrid = ({ IS_SEARCH, IS_ADMIN_SEARCH, IS_SUSPENDED }) => {
  const containerRef = useRef(null);
  const { t } = useTranslation();
  const { id: currentBusinessId } = useBusiness();
  const [isLoading, setIsLoading] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  const {
    dispatch,
    searchParams,
    state: { selected, pagination },
  } = DancerSearchCTX.useDancerSearchCtx(IS_ADMIN_SEARCH);
  const QUERY = IS_ADMIN_SEARCH ? GET_DANCER_ADMIN_SEARCH_SQL : GET_DANCER_MANAGER_SEARCH_SQL;
  const FIELD = IS_ADMIN_SEARCH ? 'admin_dancers' : 'manager_dancers';
  const { loading, error, data, fetchMore, client } = useQuery(QUERY, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: false,
    variables: { ...searchParams, suspended: IS_SUSPENDED, hasLocation: true },
    onError: (err) =>
      logError(
        err,
        IS_ADMIN_SEARCH ? 'GET_DANCER_ADMIN_SEARCH_SQL' : 'GET_DANCER_MANAGER_SEARCH_SQL',
        DancerSearchGrid.displayName
      ),

    onCompleted: (data) => {
      setIsMounted(true);
      dispatch(
        DancerSearchCTX.setDancerSearchFields({
          selected: [],
          total: data[FIELD].totalCount,
        })
      );
      dispatch(DancerSearchCTX.setDancerSearchPagination(data[FIELD].pageInfo));
    },
  });
  const items = data?.admin_dancers?.nodes || data?.manager_dancers?.nodes;

  const handleSelectedDancer = useCallback(
    (id) => {
      const item = items.find((item) => item.id == id);
      dispatch(
        DancerSearchCTX.setDancerSearchField(
          'selected',
          !!selected.find((item) => item.id === id) ? selected.filter((item) => item.id !== id) : [...selected, item]
        )
      );
    },
    [dispatch, selected, items]
  );

  const handleOnActionSuccess = useCallback(
    (id) => {
      dispatch(
        DancerSearchCTX.setDancerSearchField(
          'selected',
          selected.filter((item) => item.id !== id)
        )
      );
      removeDancerFromList(IS_ADMIN_SEARCH, client, id, searchParams);
    },
    [selected, IS_ADMIN_SEARCH, client, searchParams]
  );

  const isSelected = (id) => (IS_SEARCH ? !!selected.find((item) => item.id == id) : false);

  const handleOnScroll = async () => {
    const target = containerRef.current;
    const bottomReached = target.scrollTop + target.clientHeight >= target.scrollHeight - 250;
    if (bottomReached && !loading && !isLoading && pagination.hasNextPage) {
      setIsLoading(true);
      await fetchMore({
        variables: { after: pagination.endCursor },
        updateQuery: ({ [FIELD]: { nodes } }, { fetchMoreResult }) => {
          dispatch(DancerSearchCTX.setDancerSearchPagination(fetchMoreResult[FIELD].pageInfo));
          return {
            ...fetchMoreResult[FIELD],
            [FIELD]: {
              ...fetchMoreResult[FIELD],
              nodes: [...nodes, ...fetchMoreResult[FIELD].nodes],
            },
          };
        },
      });
      setIsLoading(false);
    }
  };

  const throttledScroll = throttle(handleOnScroll, 1000, { trailing: true, leading: false });

  useEffect(() => {
    if (!containerRef.current) return;
    containerRef.current.scrollTop = 0;
  }, [containerRef.current]);

  useEffect(() => {
    if (loading) {
      throttledScroll.cancel();
    }
  }, [loading]);

  useEffect(() => {
    setIsMounted(false);
    dispatch(
      DancerSearchCTX.setDancerSearchFields({
        selected: [],
        total: 0,
      })
    );
    dispatch(DancerSearchCTX.setDancerSearchPagination({ endCursor: '', hasNextPage: false }));
  }, [IS_SEARCH, IS_ADMIN_SEARCH, IS_SUSPENDED]);

  if (!isMounted || (loading && !items?.length)) {
    return (
      <div className={S.loadingRow}>
        <LoadingDiscoWithContainer />
      </div>
    );
  }

  if (error) {
    return <h1 className={S.errorMessage}>{t('errors.generic')}</h1>;
  }

  if (items?.length === 0) {
    return <h3 className={S.warningHeading}>{t('dancersPage.no_dancers')}</h3>;
  }

  return (
    <>
      <div className={S.dancerGrid} onScroll={!loading && !isLoading ? throttledScroll : null} ref={containerRef}>
        {items?.map(({ id }) => {
          return (
            <DancerGridItem
              isScalable
              hasOptions={!searchParams.deleted}
              dancerId={id}
              key={`DANCER_GRID_${id}`}
              hasLocation={!searchParams.isSuspended}
              isSelected={isSelected(id)}
              selectDancer={!IS_ADMIN_SEARCH ? handleSelectedDancer : null}
              removeDancer={handleOnActionSuccess}
              businessId={IS_ADMIN_SEARCH ? currentBusinessId : searchParams.businessId}
            />
          );
        })}
        {pagination.hasNextPage && (
          <div className={S.loadingRow}>
            <LoadingDiscoWithContainer />
          </div>
        )}
      </div>
    </>
  );
};

export { DancerSearchGrid };
