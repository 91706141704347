import { DANCER_GRID_FRAGMENT } from './dancer-grid-fragment';
import { gql } from 'apollo-boost';

export const GET_VERIFICATION_FRAGMENT = gql`
  ${DANCER_GRID_FRAGMENT}

  fragment VerificationCheckFragment on VerificationCheck {
    id
    sentence
    rejection_text
    verified
    first_image {
      id
      medium
    }
    moderated_at
    moderated_by {
      name
    }
    updated_at
    created_at
    dancer {
      ...DancerGridItem
      images {
        id
        thumb
        medium
        is_verified
      }
      registration_code {
        id
        code
        business {
          id
          name
        }
        user {
          id
          name
        }
      }
    }
  }
`;
