import { useApolloClient, useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import React, { useCallback, useEffect, useRef } from 'react';
import actionCable from 'actioncable';
import { useTranslation } from 'react-i18next';

import { useBusiness, useUser } from 'graphql/graph-hooks';
import { CONVERSATION_FRAGMENT } from 'graphql/fragments/conversation-fragment';
import { GET_MESSAGES_PAGINATED } from 'graphql/queries/messages-query';
import * as CachedMessages from 'graphql/cache/messages';
import * as CachedConversations from 'graphql/cache/conversations';
import { APIConstants } from 'helpers/constants';
import { useFetchMore as useFetchMoreMessages } from 'components/global-hooks';
import S from './chat-msg-area.module.scss';
import { ChatMsgBody } from './chat-msg-body';
import { ChatMsgFooter } from './chat-msg-footer';
import { ChatMsgHead } from './chat-msg-head';
import * as PageChatCtx from '../chat-ctx';

type ChatMsgAreaProps = {
  isAdminChat: boolean;
  avatarOnClick: any;
};

type msgReduceOutput = {
  firstUnreadId: string | null;
  lastMsgId: string | null;
};

type actionCableMsg = {
  type: string;
  message: MsgItemType;
};

type receivedType = {
  event: actionCableMsg;
};

const { socket_protocol, domain } = APIConstants;
const SOCKET_URL = `${socket_protocol}://${domain}/cable?auth_token=`;

export const ChatMsgArea: React.FC<ChatMsgAreaProps> = ({ isAdminChat, avatarOnClick }): React.ReactElement => {
  const CLIENT = useApolloClient();
  const { t } = useTranslation();
  const {
    dispatch,
    state: { firstUnreadId, lastMsgId, conversationId, chatBizId: businessId },
  } = PageChatCtx.usePageChatCtx();
  const { id: userId, admin: isAdmin } = useUser();
  const CABLE = useRef<any>(actionCable.createConsumer(`${SOCKET_URL}${localStorage.getItem('token')}`));

  const { id: currentBusinessId } = useBusiness();

  const {
    isLoading: isFetchMoreLoading,
    fetchMore: fetchMoreMessages,
    error: fetchMoreError,
  } = useFetchMoreMessages('messages_paginated', true);

  const DATA = CLIENT.readFragment({
    fragment: CONVERSATION_FRAGMENT,
    fragmentName: 'conversationFragment',
    id: `Conversation:${conversationId}`,
    variables: {
      isAdmin,
      blockedByBusinessId: currentBusinessId,
      chatBizId: businessId,
      inGroupBusinessId: currentBusinessId,
    },
  });

  const TARGET = DATA?.user;

  const {
    loading: processingLoad,
    data,
    error,
    refetch,
    fetchMore,
  } = useQuery(GET_MESSAGES_PAGINATED, {
    skip: !conversationId,
    variables: { conversationId: conversationId, last: 20 },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const MESSAGES: MsgItemType[] = data?.messages_paginated.nodes ?? [];
      if (MESSAGES.length > 0) {
        const { firstUnreadId, lastMsgId } = MESSAGES.reduce<msgReduceOutput>(
          (acm, { id, sender, read }) => {
            return !read && sender?.id === TARGET?.id
              ? {
                  firstUnreadId: acm.firstUnreadId || id,
                  lastMsgId: id,
                }
              : acm;
          },
          { firstUnreadId: null, lastMsgId: null }
        );
        dispatch(PageChatCtx.setChatFields({ firstUnreadId, lastMsgId }));
      }
    },
  });

  //this gets both sent to and from server
  const createCable = useCallback(() => {
    const LAST_CONVO = CABLE.current.subscriptions.subscriptions[0];
    if (LAST_CONVO) {
      CABLE.current.subscriptions.remove(LAST_CONVO);
    }

    CABLE.current.subscriptions.create(
      {
        channel: 'ConversationChannel',
        data: { conversation_id: conversationId },
      },
      {
        received: ({ event: { type, message } }: receivedType) => {
          if (type === 'Message#Create') {
            const MSG = {
              __typename: 'Message',
              id: message.id.toString(),
              body: message.body,
              updated_at: message.updated_at,
              created_at: message.created_at,
              read: message.read,
              sender: {
                __typename: 'User',
                id: message.sender.id.toString(),
                name: message.sender.name,
              },
            };

            const conversations = CachedConversations.getCachedConversations(
              CLIENT,
              businessId,
              userId,
              isAdmin,
              currentBusinessId
            );
            const CONVERSATION = conversations.find(({ id }) => id === conversationId);

            if (!CONVERSATION) {
              CachedConversations.setCachedConversations(CLIENT, businessId, userId, isAdmin, currentBusinessId, [
                {
                  __typename: 'Conversation',
                  id: conversationId!,
                  updated_at: MSG.created_at,
                  last_message: MSG,
                  last_message_sent: MSG.created_at,
                  business_unread_messages_count: 0,
                  user: TARGET,
                },
                ...conversations.filter(({ id }) => id !== conversationId),
              ]);

              const messages = CachedMessages.getCachedMessages(CLIENT, conversationId!);
              CachedMessages.setCachedMessages(CLIENT, conversationId, [...messages, MSG]);

              return;
            }

            //Uses writeQuery instead of writeData so userList updates
            //userList Array won't update if conversations aren't changed
            CachedConversations.setCachedConversations(CLIENT, businessId, userId, isAdmin, currentBusinessId, [
              {
                __typename: 'Conversation',
                id: conversationId!,
                updated_at: MSG.created_at,
                last_message: MSG,
                last_message_sent: MSG.created_at,
                business_unread_messages_count: (CONVERSATION.business_unread_messages_count +=
                  userId !== MSG.sender.id ? 1 : 0),
                user: CONVERSATION.user,
              },
              ...conversations.filter(({ id }) => id !== conversationId),
            ]);

            const messages = CachedMessages.getCachedMessages(CLIENT, conversationId!);
            CachedMessages.setCachedMessages(CLIENT, conversationId, [...messages, MSG]);

            if (MSG.sender.id === CONVERSATION.user.id) {
              dispatch(
                PageChatCtx.setChatFields({
                  firstUnreadId: conversationId ? null : firstUnreadId || MSG.id,
                  lastMsgId: MSG.id,
                  scrollToBottom: true,
                })
              );
            }
          } else if (type !== 'Message#Update') {
            console.log('Warning: unrecognized event type detected:', type);
          } else {
            console.log('is cable Update');
          }
        },
      }
    );
  }, [dispatch, CLIENT, CABLE, TARGET, firstUnreadId, conversationId, businessId, userId, isAdmin, currentBusinessId]);

  useEffect(() => {
    const CONNECTION = CABLE.current;
    if (conversationId) {
      createCable();
    }
    return () => CONNECTION.disconnect();
  }, [conversationId, createCable]);

  if (!conversationId) {
    return (
      <div className={classNames(S.chatMsgArea, { [S.isAdmin]: isAdminChat })}>
        <h3 className={S.warnMsg}>{t('chatPage.select_dancer_in_panel')}</h3>
      </div>
    );
  }

  const handleOnScrollTop = () => {
    if (!data.messages_paginated.pageInfo.hasPreviousPage) {
      return;
    }
    dispatch(PageChatCtx.setChatFields({ scrollToBottom: false }));
    fetchMoreMessages(fetchMore, { before: data.messages_paginated.pageInfo.startCursor });
  };

  const loadingError = error && t('chatPage.error_loading_conversation');

  return (
    <div className={classNames(S.chatMsgArea, { [S.isAdmin]: isAdminChat })}>
      <ChatMsgHead {...TARGET} conversationId={conversationId} businessId={businessId} avatarOnClick={avatarOnClick} />
      <ChatMsgBody
        targetId={TARGET.id}
        isLoading={processingLoad}
        isFetchMoreLoading={isFetchMoreLoading}
        messages={data?.messages_paginated.nodes}
        conversationId={conversationId}
        onScrollTop={handleOnScrollTop}
        error={fetchMoreError || loadingError}
      />
      {((isAdminChat && isAdmin) || (!isAdminChat && !isAdmin)) && (
        <ChatMsgFooter target={TARGET} conversationId={conversationId} />
      )}
    </div>
  );
};

ChatMsgArea.displayName = 'ChatMsgArea';
ChatMsgArea.propTypes = {
  isAdminChat: PropTypes.bool.isRequired,
};
