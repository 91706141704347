import { gql } from 'apollo-boost';

import { CONVERSATION_FRAGMENT } from '../fragments/conversation-fragment';

export const CONVERSATION_MARK_AS_READ = gql`
  ${CONVERSATION_FRAGMENT}
  mutation ConversationMarkAsRead($id: ID!, $isAdmin: Boolean!, $blockedByBusinessId: ID, $inGroupBusinessId: ID) {
    conversation_mark_as_read(input: { id: $id }) {
      conversation {
        ...conversationFragment
      }
    }
  }
`;
