import { NOTIFICATION_TYPES } from '../constants';
import i18next from 'i18next';
const { t } = i18next;

const CONFIG = {
  title: {
    minChar: 5,
    maxChar: 120,
  },
  message: {
    minChar: 5,
    maxChar: [2000, 2000], // group/no group
  },
};

const NOTIFICATION_VALUES = Object.values(NOTIFICATION_TYPES).map(({ value }) => value);

export const validateTitle = (text = '', values) => {
  if (!Object.keys(values).reduce((a, c) => a + values[c], ''))
    return t('notificationsPage.at_least_one_language_required');
  else return text ? validateMinMax('Title', text.length, CONFIG.title.minChar, CONFIG.title.maxChar) : '';
};

export const validateMessage = (values, lang, groupId) => {
  const MAX = CONFIG.message.maxChar[groupId ? 0 : 1];
  return values.title[lang]
    ? validateMinMax(t('modals.message.message'), values.message[lang].length, CONFIG.message.minChar, MAX)
    : '';
};

const validateMinMax = (field, length, min, max) => {
  if (length < min) return t('errors.validation.min_length', { field_name: field, min_length: min });
  else if (length > max) return t('errors.validation.max_length', { field_name: field, max_length: max });
  else return '';
};

export const validateAudience = (opt) => {
  if (opt === '') return t('errors.validation.required', { field_name: t('modals.message.target_audience') });
  else if (!NOTIFICATION_VALUES.includes(opt)) return 'Type is invalid';
  else return '';
};

export const validateUrl = (string) => {
  try {
    if (!string) throw 'empty';
    new URL(string);
    return '';
  } catch (err) {
    return 'invalid_url';
  }
};
